// In Dashboard.js
import React, { useState } from 'react';
import Header from '../components/layout/Header';
import LineCard from '../components/dashboard/LineCard';
import LastDelaysPanel from '../components/dashboard/LastDelaysPanel';
import LineDetailModal from '../components/dashboard/LineDetailModal';
import PerformanceLeaderboard from '../components/dashboard/PerformanceLeaderboard';
import NetworkPerformance from '../components/dashboard/NetworkPerformance';
import NetworkDisruptionTimeline from '../components/dashboard/NetworkDisruptionTimeline';
import { STATUS_MAPPING, STATUS_SEVERITY } from '../utils/statusUtils';
import latestData from '../data/latestData.json';

const Dashboard = () => {
  const [latestStatuses] = useState(latestData.statuses);
  const [lastUpdated] = useState(new Date(latestData.timestamp));
  const [isLoading] = useState(false);
  const [error] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const [timeRange, setTimeRange] = useState('24h'); // Add this line

  // Also add a handler to sync time range across components
  const handleTimeRangeChange = (newRange) => {
    setTimeRange(newRange);
  };

  const getDelayedLines = () => {
    return Object.entries(latestStatuses)
      .filter(([_, data]) => {
        const status = data.status;
        const severity = STATUS_MAPPING[status]?.severity;
        return severity > STATUS_SEVERITY.GOOD && severity !== undefined;
      })
      .map(([line, data]) => ({
        line,
        status: data.status,
        since: data.timestamp || lastUpdated
      }));
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Error</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header lastUpdated={lastUpdated} />
      <main className="max-w-7xl mx-auto px-4 py-4 sm:px-6 sm:py-8">
        <NetworkPerformance statuses={latestStatuses} />
        <NetworkDisruptionTimeline timeRange={timeRange} />
        <LastDelaysPanel delayedLines={getDelayedLines()} isLoading={isLoading} />
        <PerformanceLeaderboard 
          timeRange={timeRange}
          onTimeRangeChange={handleTimeRangeChange}
        />

        <div className="mt-6 sm:mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {Object.entries(latestStatuses).map(([line, data]) => (
            <LineCard
              key={line}
              line={line}
              status={data}
              onClick={() => setSelectedLine(line)}
            />
          ))}
        </div>

        {selectedLine && (
          <LineDetailModal
            line={selectedLine}
            status={latestStatuses[selectedLine]}
            onClose={() => setSelectedLine(null)}
          />
        )}
      </main>
    </div>
  );
};

export default Dashboard;