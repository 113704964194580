// src/utils/lineNameUtils.js
export const formatLineName = (lineName) => {
    // Create a temporary div to decode HTML entities
    const txt = document.createElement("textarea");
    txt.innerHTML = lineName;
    
    // Get decoded text and clean up any remaining issues
    let decodedName = txt.value
      .replace(/&amp;/g, '&')
      .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
      .replace(/&[a-z]+;/g, '&');
  
    return decodedName;
  };