// src/utils/dataUtils.js
import { STATUS_MAPPING, STATUS_SEVERITY } from './statusUtils';
import { formatLineName } from './lineNameUtils';

/**
 * Gets the latest timestamp aligned to 5-minute intervals
 * @returns {Date} Current time rounded down to nearest 5-minute mark
 */
export function getLatestAlignedTimestamp() {
  const now = new Date();
  const interval = 5 * 60 * 1000; // 5 minutes in milliseconds
  return new Date(Math.floor(now.getTime() / interval) * interval);
}

/**
 * Fills missing data points in historical data and extends to current time
 * @param {Array} data Array of historical data entries
 * @returns {Array} Processed array with filled data points
 */
export function fillMissingData(data) {
  if (!data || data.length === 0) return [];

  const sortedData = [...data].sort((a, b) => 
    new Date(a.timestamp) - new Date(b.timestamp)
  );

  const filledData = [];
  const interval = 5 * 60 * 1000; // 5 minutes in milliseconds
  
  let currentTime = new Date(sortedData[0].timestamp).getTime();
  const targetEndTime = new Date(
    Math.floor(new Date().getTime() / interval) * interval
  ).getTime();

  let lastKnownStatuses = { ...sortedData[0].statuses };
  let dataIndex = 0;

  while (currentTime <= targetEndTime) {
    const currentTimestamp = new Date(currentTime).toISOString();
    
    while (
      dataIndex < sortedData.length && 
      new Date(sortedData[dataIndex].timestamp).getTime() <= currentTime
    ) {
      lastKnownStatuses = {
        ...lastKnownStatuses,
        ...sortedData[dataIndex].statuses
      };
      dataIndex++;
    }

    filledData.push({
      timestamp: currentTimestamp,
      statuses: { ...lastKnownStatuses }
    });

    currentTime += interval;
  }

  return filledData;
}

/**
 * Calculate time difference between two data points in minutes
 * @param {string} current Current timestamp
 * @param {string} next Next timestamp
 * @returns {number} Duration in minutes
 */
export function calculateDuration(current, next) {
  const currentTime = new Date(current).getTime();
  const nextTime = new Date(next).getTime();
  return (nextTime - currentTime) / (60 * 1000); // Convert to minutes
}

/**
 * Calculate weighted metrics taking into account time between updates
 * @param {Array} data Historical data
 * @param {string} line Line identifier (optional)
 * @returns {Object} Metrics with proper time weighting
 */
export function calculateWeightedMetrics(data, line = null) {
  if (!data || data.length === 0) return {};
  
  const metrics = {};
  const sortedData = [...data].sort((a, b) => 
    new Date(a.timestamp) - new Date(b.timestamp)
  );

  sortedData.forEach((snapshot, index) => {
    const duration = index < sortedData.length - 1 
      ? calculateDuration(snapshot.timestamp, sortedData[index + 1].timestamp)
      : 5; // Default 5-minute duration for last entry

    const statuses = line 
      ? { [line]: snapshot.statuses[line] }
      : snapshot.statuses;

    Object.entries(statuses).forEach(([lineName, status]) => {
      if (!metrics[lineName]) {
        metrics[lineName] = {
          totalMinutes: 0,
          goodServiceMinutes: 0,
          currentGoodStreak: 0,
          longestGoodStreak: 0,
          currentBadStreak: 0,
          longestBadStreak: 0
        };
      }

      const statusStr = typeof status === 'string' ? status : status.status;
      
      // Skip non-service affecting statuses
      if (
        statusStr === 'Service Closed' || 
        statusStr === 'Planned closure' ||
        STATUS_MAPPING[statusStr]?.severity === STATUS_SEVERITY.NONE || 
        STATUS_MAPPING[statusStr]?.severity === undefined
      ) {
        return;
      }

      const isGoodService = statusStr === 'Good service';
      metrics[lineName].totalMinutes += duration;

      if (isGoodService) {
        metrics[lineName].goodServiceMinutes += duration;
        metrics[lineName].currentGoodStreak += duration;
        metrics[lineName].currentBadStreak = 0;
        metrics[lineName].longestGoodStreak = Math.max(
          metrics[lineName].longestGoodStreak,
          metrics[lineName].currentGoodStreak
        );
      } else {
        metrics[lineName].currentBadStreak += duration;
        metrics[lineName].currentGoodStreak = 0;
        metrics[lineName].longestBadStreak = Math.max(
          metrics[lineName].longestBadStreak,
          metrics[lineName].currentBadStreak
        );
      }
    });
  });

  return metrics;
}

/**
 * Calculate line reliability with proper weighting based on duration
 * @param {Array} data Processed historical data
 * @param {string} line Line identifier
 * @returns {Object} Reliability metrics
 */
export function calculateLineReliability(data, line) {
  const metrics = calculateWeightedMetrics(data, line)[line];
  
  if (!metrics) return null;

  return {
    reliability: metrics.totalMinutes > 0 
      ? (metrics.goodServiceMinutes / metrics.totalMinutes) * 100 
      : 100,
    longestGoodStreak: Math.floor(metrics.longestGoodStreak),
    longestBadStreak: Math.floor(metrics.longestBadStreak),
    totalMinutes: metrics.totalMinutes
  };
}

/**
 * Process historical data for visualization while maintaining accuracy
 * @param {Array} data Raw historical data
 * @param {string} timeRange Time range (24h, 7d, 30d)
 * @returns {Array} Processed data for charts
 */
export function processChartData(data, timeRange = '24h') {
  if (!data || data.length === 0) return [];

  const now = getLatestAlignedTimestamp();
  let startTime;

  switch (timeRange) {
    case '7d':
      startTime = new Date(now);
      startTime.setDate(startTime.getDate() - 7);
      break;
    case '30d':
      startTime = new Date(now);
      startTime.setDate(startTime.getDate() - 30);
      break;
    default: // 24h
      startTime = new Date(now);
      startTime.setHours(startTime.getHours() - 24);
  }

  const filteredData = data.filter(entry => {
    const timestamp = new Date(entry.timestamp);
    return timestamp >= startTime && timestamp <= now;
  });

  return fillMissingData(filteredData);
}

/**
 * Calculate performance metrics for all lines or a specific line
 * @param {Array} data Historical data
 * @param {string} line Optional line identifier for single-line metrics
 * @returns {Array|Object} Processed metrics
 */
export function calculatePerformanceMetrics(data, line = null) {
  if (!data || data.length === 0) return line ? null : [];

  const weightedMetrics = calculateWeightedMetrics(data, line);

  if (line) {
    const metrics = weightedMetrics[line];
    return metrics ? {
      reliability: (metrics.goodServiceMinutes / metrics.totalMinutes) * 100,
      longestGoodStreak: Math.floor(metrics.longestGoodStreak),
      longestBadStreak: Math.floor(metrics.longestBadStreak)
    } : null;
  }

  return Object.entries(weightedMetrics)
    .filter(([_, metrics]) => metrics.totalMinutes > 0)
    .map(([lineName, metrics]) => ({
      line: formatLineName(lineName),
      originalLine: lineName,
      goodServicePercentage: (metrics.goodServiceMinutes / metrics.totalMinutes) * 100,
      longestGoodStreak: Math.floor(metrics.longestGoodStreak),
      longestBadStreak: Math.floor(metrics.longestBadStreak)
    }))
    .sort((a, b) => b.goodServicePercentage - a.goodServicePercentage);
}

// Export all utility functions
export const dataUtils = {
  fillMissingData,
  processChartData,
  calculateDuration,
  calculateWeightedMetrics,
  calculateLineReliability,
  calculatePerformanceMetrics,
  getLatestAlignedTimestamp
};