// src/components/dashboard/StatusBadge.js
import React from 'react';
import { getStatusColor, getStatusCategory } from '../../utils/statusUtils';

const StatusBadge = ({ status }) => {
  if (!status) return null;
  
  return (
    <div className="inline-flex flex-wrap items-center gap-2">
      <div className={`w-2 h-2 rounded-full ${getStatusColor(status)}`} />
      <span className="text-xs sm:text-sm font-medium">{status}</span>
      <span className="text-xs text-gray-500 hidden sm:inline">
        ({getStatusCategory(status)})
      </span>
    </div>
  );
};

export default StatusBadge;