// src/components/dashboard/LineCard.js
import React, { useState, useEffect } from 'react';
import StatusBadge from './StatusBadge';
import { formatDistanceToNow } from 'date-fns';
import { getLineColor } from '../../utils/lineColors';
import { formatLineName } from '../../utils/lineNameUtils';
import { fetchLineHistory } from '../../utils/api';
import { processChartData, calculatePerformanceMetrics } from '../../utils/dataUtils';

const LineCard = ({ line, status, onClick }) => {
    const lineColors = getLineColor(line);
    const formattedLineName = formatLineName(line);
    const [overallScore, setOverallScore] = useState(null);

    useEffect(() => {
        const calculateOverallScore = async () => {
            try {
                const data = await fetchLineHistory(line, '30d');
                const processedData = processChartData(data, '30d');
                const metrics = calculatePerformanceMetrics(processedData, line);
                setOverallScore(metrics?.reliability ?? 100);
            } catch (error) {
                console.error('Failed to calculate overall score:', error);
            }
        };

        calculateOverallScore();
    }, [line]);

    const getScoreColor = (score) => {
        if (score >= 90) return 'text-green-600';
        if (score >= 75) return 'text-blue-600';
        if (score >= 50) return 'text-yellow-600';
        return 'text-red-600';
    };

    return (
        <div 
          onClick={onClick}
          className="relative overflow-hidden rounded-xl shadow-lg cursor-pointer transform transition-all duration-300 hover:scale-105 active:scale-95"
          style={{
            background: 'white',
            borderTop: `4px solid ${lineColors.primary}`
          }}
        >
          <div className="p-4 sm:p-6">
            <div className="flex justify-between items-start mb-3 sm:mb-4">
              <h3 
                className="text-base sm:text-lg font-semibold"
                style={{ color: lineColors.primary }}
              >
                {formattedLineName}
              </h3>
              {overallScore !== null && (
                <div className={`text-sm font-medium ${getScoreColor(overallScore)}`}>
                  {Math.round(overallScore)}% Reliability
                </div>
              )}
            </div>
        
            <StatusBadge status={status.status} />
            
            {status.timestamp && (
              <div className="mt-3 sm:mt-4 text-xs sm:text-sm text-gray-500">
                Updated {formatDistanceToNow(new Date(status.timestamp))} ago
              </div>
            )}
            
            <div 
              className="mt-3 sm:mt-4 text-xs sm:text-sm hover:opacity-75 transition-opacity"
              style={{ color: lineColors.primary }}
            >
              View historical performance →
            </div>
          </div>
        </div>
    );
};

export default LineCard;