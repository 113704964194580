// src/components/dashboard/NetworkPerformance.js
import React, { useState, useEffect } from 'react';
import { ArrowUpCircle, ArrowDownCircle, Activity, Clock, AlertTriangle, Info, Gauge } from 'lucide-react';
import { ResponsiveContainer, Tooltip as RechartsTooltip, ReferenceLine, AreaChart, Area } from 'recharts';
import { fetchLineHistory } from '../../utils/api';
import { STATUS_MAPPING, STATUS_SEVERITY } from '../../utils/statusUtils';
import { processChartData, getLatestAlignedTimestamp } from '../../utils/dataUtils';

// Tooltip Component
const Tooltip = ({ children }) => (
  <div className="group relative inline-block">
    <Info className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-help" />
    <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 w-48 text-center z-10">
      {children}
      <div className="absolute top-full left-1/2 -translate-x-1/2 -mt-2 border-4 border-transparent border-t-gray-900"></div>
    </div>
  </div>
);

// SparklineChart Component
const SparklineChart = ({ data, type }) => {
  const getColor = () => {
    switch(type) {
      case 'health':
        return '#10B981';
      case 'good-service':
        return '#3B82F6';
      case 'disruptions':
        return '#EF4444';
      default:
        return '#4B5563';
    }
  };

  const formatTooltip = (value) => {
    switch(type) {
      case 'health':
      case 'good-service':
        return `${value.toFixed(1)}%`;
      case 'disruptions':
        return value === 1 ? '1 line' : `${value} lines`;
      default:
        return value;
    }
  };

  const average = data?.length > 0 
    ? data.reduce((acc, cur) => acc + cur.value, 0) / data.length
    : 0;

  return (
    <div className="h-16 -mx-4">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
          <defs>
            <linearGradient id={`gradient-${type}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={getColor()} stopOpacity={0.2} />
              <stop offset="100%" stopColor={getColor()} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            stroke={getColor()}
            fill={`url(#gradient-${type})`}
            strokeWidth={1.5}
            dot={false}
            isAnimationActive={false}
          />
          <RechartsTooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div className="bg-gray-900 text-white text-xs px-2 py-1 rounded">
                    <div>{payload[0].payload.time}</div>
                    <div>{formatTooltip(payload[0].value)}</div>
                  </div>
                );
              }
              return null;
            }}
          />
          <ReferenceLine 
            y={average} 
            stroke={getColor()} 
            strokeDasharray="3 3" 
            strokeOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
      <div className="text-xs text-gray-400 mt-1 ml-2">24h trend</div>
    </div>
  );
};

// HealthIndicator Component
const HealthIndicator = ({ value, trend = 0, label, sparklineData, type }) => {
  const getTrendIcon = () => {
    if (trend > 0) return <ArrowUpCircle className="w-5 h-5 text-green-500" />;
    if (trend < 0) return <ArrowDownCircle className="w-5 h-5 text-red-500" />;
    return null;
  };

  const getBgColor = () => {
    if (value >= 90) return 'from-green-50 to-green-100';
    if (value >= 70) return 'from-blue-50 to-blue-100';
    if (value >= 50) return 'from-yellow-50 to-yellow-100';
    return 'from-red-50 to-red-100';
  };

  const getHealthStatus = () => {
    if (value >= 90) return { text: 'Excellent', icon: '🌟' };
    if (value >= 70) return { text: 'Good', icon: '👍' };
    if (value >= 50) return { text: 'Fair', icon: '😊' };
    return { text: 'Needs Attention', icon: '⚠️' };
  };

  const { text, icon } = getHealthStatus();

  return (
    <div className={`bg-gradient-to-br ${getBgColor()} rounded-xl p-4 sm:p-6 relative overflow-hidden`}>
      <div className="absolute top-0 right-0 p-2">
        <span className="text-2xl">{icon}</span>
      </div>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
        <div>
          <div className="text-3xl sm:text-4xl font-bold text-gray-800 mb-2">
            {value.toFixed(1)}%
          </div>
          <div className="flex items-center space-x-2 mt-1">
            <span className="text-sm sm:text-base text-gray-600">{label}</span>
            <Tooltip>
              Overall network health score based on service status across all lines.
              100% means all lines are running good service.
            </Tooltip>
          </div>
          <div className="text-sm font-medium mt-2" style={{ color: value >= 70 ? '#10B981' : '#EF4444' }}>
            {text}
          </div>
        </div>
        {trend !== 0 && (
          <div className="flex items-center space-x-1 text-sm mt-2 sm:mt-0 bg-white rounded-full px-3 py-1 shadow-sm">
            {getTrendIcon()}
            <span className={trend > 0 ? 'text-green-600' : 'text-red-600'}>
              {Math.abs(trend)}%
            </span>
          </div>
        )}
      </div>
      {sparklineData && <SparklineChart data={sparklineData} type={type} />}
    </div>
  );
};

// ServiceStatusBox Component
const ServiceStatusBox = ({ label, value, subtext, icon: Icon, sparklineData, type }) => (
  <div className="bg-white rounded-xl p-4 border border-gray-100 hover:shadow-lg transition-all duration-300">
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center space-x-2">
        <span className="text-gray-600 text-sm">{label}</span>
        <Tooltip>{subtext}</Tooltip>
      </div>
      <div className="relative">
        <Icon className="w-4 h-4 text-gray-400" />
        <div className="absolute -top-1 -right-1 w-2 h-2 bg-blue-500 rounded-full animate-pulse"></div>
      </div>
    </div>
    <div className="text-2xl font-bold text-gray-800 mb-2">{value}</div>
    {sparklineData && <SparklineChart data={sparklineData} type={type} />}
  </div>
);

// NetworkPerformance Component
const NetworkPerformance = ({ statuses }) => {
  const [historicalData, setHistoricalData] = useState([]);
  const [sparklines, setSparklines] = useState({
    health: [],
    goodService: [],
    issues: []
  });
  const [showExplanation, setShowExplanation] = useState(false);

  // Helper function to calculate health from statuses
  const calculateHealth = (statuses) => {
    const activeLines = Object.entries(statuses).filter(([_, status]) => {
      const statusStr = typeof status === 'string' ? status : status.status;
      const severity = STATUS_MAPPING[statusStr]?.severity;
      return severity !== undefined && 
             severity !== STATUS_SEVERITY.NONE && 
             statusStr !== 'Service Closed' && 
             statusStr !== 'Planned closure';
    });

    const goodService = activeLines.filter(([_, status]) => {
      const statusStr = typeof status === 'string' ? status : status.status;
      return statusStr === 'Good service';
    }).length;

    return activeLines.length > 0 ? (goodService / activeLines.length) * 100 : 100;
  };

  useEffect(() => {
    const loadHistoricalData = async () => {
      try {
        const data = await fetchLineHistory(null, '24h');
        const processedData = processChartData(data, '24h');
        console.log('Processed historical data:', processedData);
        setHistoricalData(processedData);

        const healthData = [];
        const goodServiceData = [];
        const issuesData = [];

        processedData.forEach(snapshot => {
          const activeLines = Object.entries(snapshot.statuses).filter(([_, status]) => {
            const statusStr = typeof status === 'string' ? status : status.status;
            const severity = STATUS_MAPPING[statusStr]?.severity;
            return severity !== undefined && 
                   severity !== STATUS_SEVERITY.NONE && 
                   statusStr !== 'Service Closed' && 
                   statusStr !== 'Planned closure';
          });
        
          const goodServiceCount = activeLines.filter(([_, status]) => {
            const statusStr = typeof status === 'string' ? status : status.status;
            return statusStr === 'Good service';
          }).length;
        
          const severeIssues = activeLines.filter(([_, status]) => {
            const statusStr = typeof status === 'string' ? status : status.status;
            return ['Severe delays', 'Part suspended', 'Suspended'].includes(statusStr);
          }).length;

          const health = activeLines.length > 0 ? (goodServiceCount / activeLines.length) * 100 : 100;

          const timeStr = new Date(snapshot.timestamp).toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit'
          });

          healthData.push({ 
            time: timeStr,
            value: health,
            timestamp: new Date(snapshot.timestamp)
          });

          goodServiceData.push({ 
            time: timeStr,
            value: activeLines.length > 0 ? (goodServiceCount / activeLines.length) * 100 : 0,
            timestamp: new Date(snapshot.timestamp)
          });

          issuesData.push({ 
            time: timeStr,
            value: severeIssues,
            timestamp: new Date(snapshot.timestamp)
          });
        });

        // Ensure data extends to current time
        const latestTimestamp = getLatestAlignedTimestamp();
        
        [healthData, goodServiceData, issuesData].forEach(dataset => {
          if (dataset.length > 0) {
            const lastEntry = dataset[dataset.length - 1];
            if (lastEntry.timestamp < latestTimestamp) {
              dataset.push({
                ...lastEntry,
                timestamp: latestTimestamp,
                time: latestTimestamp.toLocaleTimeString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit'
                })
              });
            }
          }
        });

        setSparklines({
          health: healthData,
          goodService: goodServiceData,
          issues: issuesData
        });
      } catch (error) {
        console.error('Failed to fetch historical data:', error);
      }
    };

    loadHistoricalData();
    
    // Set up periodic refresh every 5 minutes
    const refreshInterval = setInterval(loadHistoricalData, 5 * 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, []);

  // Calculate current metrics excluding information statuses
  const activeLines = Object.entries(statuses).filter(([_, status]) => {
    const severity = STATUS_MAPPING[status.status]?.severity;
    return severity !== undefined && 
           severity !== STATUS_SEVERITY.NONE && 
           status.status !== 'Service Closed' && 
           status.status !== 'Planned closure';
  });

  const linesWithGoodService = activeLines.filter(([_, status]) => 
    status.status === 'Good service'
  ).length;

  const linesWithSevereIssues = activeLines.filter(([_, status]) => 
    ['Severe delays', 'Part suspended', 'Suspended'].includes(status.status)
  ).length;

  const networkHealth = activeLines.length > 0 
    ? (linesWithGoodService / activeLines.length) * 100 
    : 100;

  // Calculate hourly trend
  const getHourlyTrend = () => {
    if (historicalData.length < 12) return 0;
    const hourAgo = historicalData[historicalData.length - 12];
    const hourAgoHealth = calculateHealth(hourAgo.statuses);
    return parseFloat((networkHealth - hourAgoHealth).toFixed(1));
  };

  // Calculate severity breakdown
  const severityBreakdown = activeLines.reduce((acc, [_, status]) => {
    const severity = STATUS_MAPPING[status.status]?.severity || 0;
    acc[severity] = (acc[severity] || 0) + 1;
    return acc;
  }, {});

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 mb-6 sm:mb-8 hover:shadow-xl transition-shadow duration-300">
      <div className="flex flex-col sm:flex-row items-center justify-between mb-6">
        <div className="flex items-center space-x-3 mb-4 sm:mb-0">
          <Gauge className="w-6 h-6 text-blue-500" />
          <h2 className="text-lg sm:text-xl font-bold text-gray-800">Network Health</h2>
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
          <button
            onClick={() => setShowExplanation(!showExplanation)}
            className="text-blue-500 hover:text-blue-600 flex items-center space-x-1"
          >
            <Info className="w-4 h-4" />
            <span className="text-sm">How it works</span>
          </button>
          <div className="flex items-center text-sm text-gray-500">
            <Clock className="w-4 h-4 mr-1" />
            <span>Last 24 hours</span>
          </div>
          </div>
        </div>

      {showExplanation && (
        <div className="bg-blue-50 rounded-xl p-4 mb-6 text-sm text-blue-800 animate-fade-in">
          <h3 className="font-semibold mb-2">How we calculate network health:</h3>
          <ul className="list-disc pl-5 space-y-1">
            <li>Network health score is based on the percentage of lines running good service</li>
            <li>Scores above 90% are considered excellent</li>
            <li>Each line's status is updated in real-time</li>
            <li>Planned closures don't affect the health score</li>
          </ul>
        </div>
      )}
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="lg:col-span-2">
          <HealthIndicator 
            value={networkHealth}
            trend={getHourlyTrend()}
            label="Overall Network Health"
            sparklineData={sparklines.health}
            type="health"
          />
        </div>

        <ServiceStatusBox 
          label="Lines with Good Service"
          value={`${linesWithGoodService}/${activeLines.length}`}
          subtext="Number of lines currently operating with good service"
          icon={Activity}
          sparklineData={sparklines.goodService}
          type="good-service"
        />

        <ServiceStatusBox 
          label="Severe Disruptions"
          value={linesWithSevereIssues}
          subtext="Lines with severe delays, suspensions, or part suspensions"
          icon={AlertTriangle}
          sparklineData={sparklines.issues}
          type="disruptions"
        />
      </div>

      {/* Quick Stats Row */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
        <div className="bg-gray-50 rounded-lg p-3 text-center">
          <div className="font-medium text-gray-600 text-sm">Severe Issues</div>
          <div className="text-xl font-bold text-gray-800">{severityBreakdown[STATUS_SEVERITY.SEVERE] || 0}</div>
        </div>
        <div className="bg-gray-50 rounded-lg p-3 text-center">
          <div className="font-medium text-gray-600 text-sm">Minor Delays</div>
          <div className="text-xl font-bold text-gray-800">{severityBreakdown[STATUS_SEVERITY.MINOR] || 0}</div>
        </div>
        <div className="bg-gray-50 rounded-lg p-3 text-center">
          <div className="font-medium text-gray-600 text-sm">Part Suspended</div>
          <div className="text-xl font-bold text-gray-800">{severityBreakdown[STATUS_SEVERITY.MODERATE] || 0}</div>
        </div>
        <div className="bg-gray-50 rounded-lg p-3 text-center">
          <div className="font-medium text-gray-600 text-sm">Special Notices</div>
          <div className="text-xl font-bold text-gray-800">{severityBreakdown[STATUS_SEVERITY.NONE] || 0}</div>
        </div>
      </div>

      <div className="mt-4 text-xs text-gray-500 flex items-center">
        <Info className="w-4 h-4 mr-1" />
        Network health is calculated based on current service status across all lines.
        Trend shows change from previous hour.
      </div>
    </div>
  );
};

export default NetworkPerformance;