// src/components/dashboard/LastDelaysPanel.js
import React from 'react';
import StatusBadge from './StatusBadge';
import { formatDistanceToNow } from 'date-fns';
import { getLineColor } from '../../utils/lineColors';
import { formatLineName } from '../../utils/lineNameUtils';

const DelayedLineItem = ({ line, status, since }) => {
    const lineColors = getLineColor(line);
    const formattedLineName = formatLineName(line);

    return (
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0 border-b pb-3 last:border-b-0 last:pb-0">
          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <span 
              className="font-medium text-sm sm:text-base mb-1 sm:mb-0 px-2 py-1 rounded"
              style={{ 
                backgroundColor: `${lineColors.primary}15`,
                color: lineColors.primary 
              }}
            >
              {formattedLineName}
            </span>
            <StatusBadge status={status} />
          </div>
          <span className="text-xs sm:text-sm text-gray-500">
            {formatDistanceToNow(new Date(since))} ago
          </span>
        </div>
      );
    };

const LastDelaysPanel = ({ delayedLines, isLoading }) => {
  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 mb-4 sm:mb-6 animate-pulse">
        <div className="h-5 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div className="space-y-3">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
    );
  }

  if (delayedLines.length === 0) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 mb-4 sm:mb-6">
        <h2 className="text-base sm:text-lg font-semibold text-green-600 mb-2">
          All Lines Operating Normally
        </h2>
        <p className="text-sm sm:text-base text-gray-500">
          There are currently no delays in the network.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 mb-4 sm:mb-6">
      <h2 className="text-base sm:text-lg font-semibold text-gray-900 mb-4">
        Current Service Issues
      </h2>
      <div className="space-y-4">
        {delayedLines.map((lineData) => (
          <DelayedLineItem key={lineData.line} {...lineData} />
        ))}
      </div>
    </div>
  );
};

export default LastDelaysPanel;