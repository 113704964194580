// src/components/dashboard/NetworkDisruptionTimeline.js

import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js'; // Import Chart for plugin registration
import { getLineColor } from '../../utils/lineColors';
import { formatLineName } from '../../utils/lineNameUtils';
import { fetchLineHistory } from '../../utils/api';
import { processChartData } from '../../utils/dataUtils';
import { STATUS_MAPPING, STATUS_SEVERITY } from '../../utils/statusUtils';
import { Info } from 'lucide-react';

// TimeRangeSelector Component
const TimeRangeSelector = ({ selectedRange, onChange }) => {
  const ranges = [
    { id: '24h', label: 'Last 24h' },
    { id: '7d', label: 'This Week' },
    { id: '30d', label: 'This Month' }
  ];

  return (
    <div className="flex flex-wrap gap-2">
      {ranges.map((range) => (
        <button
          key={range.id}
          onClick={() => onChange(range.id)}
          className={`${
            window.innerWidth < 768
              ? 'px-2 py-1 text-xs' // Compact style for mobile
              : 'px-3 py-1.5 text-sm' // Default for larger screens
          } rounded-lg font-medium transition-all duration-200 ${
            selectedRange === range.id
              ? 'bg-blue-600 text-white shadow-md border-2 border-blue-500'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          {range.label}
        </button>
      ))}
    </div>
  );
};

// Helper function to convert HEX color to RGB
const hexToRgb = (hex) => {
  // Remove '#' if present
  hex = hex.replace('#', '');
  // Parse r, g, b values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

// NetworkDisruptionTimeline Component
const NetworkDisruptionTimeline = () => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('24h'); // Internal state for time range
  const chartRef = useRef(null);

  useEffect(() => {
    // Define the custom backgroundBands plugin
    const backgroundBandsPlugin = {
      id: 'backgroundBands',
      beforeDraw: (chart) => {
        const { ctx, chartArea, scales } = chart;
        if (!chartArea) return;

        const { left, right } = chartArea;
        const yScale = scales.y;

        ctx.save();

        // Define bands with exact severity level boundaries
        const bands = [
          { min: 1, max: 2, color: 'rgba(229, 231, 235, 0.15)' },    // Minor (1-2)
          { min: 2, max: 3, color: 'rgba(234, 179, 8, 0.1)' },      // Moderate (2-3)
          { min: 3, max: 4, color: 'rgba(249, 115, 22, 0.12)' },    // Severe (3-4)
          { min: 4, max: 5, color: 'rgba(239, 68, 68, 0.15)' }      // Suspended (4-5)
        ];

        bands.forEach(band => {
          const yTop = yScale.getPixelForValue(band.max);
          const yBottom = yScale.getPixelForValue(band.min);

          ctx.fillStyle = band.color;
          ctx.fillRect(
            left,
            yTop,
            right - left,
            yBottom - yTop
          );
        });

        ctx.restore();
      }
    };

    // Register the custom backgroundBands plugin
    Chart.register(backgroundBandsPlugin);

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchLineHistory(null, timeRange);
        const processedData = processChartData(data, timeRange);

        // Initialize fixed position tracking for each severity level
        const severityPositions = {
          1: { positions: new Map(), nextPosition: 0 },
          2: { positions: new Map(), nextPosition: 0 },
          3: { positions: new Map(), nextPosition: 0 },
          4: { positions: new Map(), nextPosition: 0 }
        };

        // First pass: assign fixed positions for each line at each severity level
        processedData.forEach(entry => {
          Object.entries(entry.statuses || {}).forEach(([line, lineData]) => {
            const severity = STATUS_MAPPING[lineData.status]?.severity || 0;
            if (severity > STATUS_SEVERITY.GOOD && !severityPositions[severity].positions.has(line)) {
              const position = severityPositions[severity].nextPosition;
              severityPositions[severity].positions.set(line, position);
              severityPositions[severity].nextPosition += 1;
            }
          });
        });

        const datasets = Object.entries(processedData[0]?.statuses || {})
          .sort(([a], [b]) => formatLineName(a).localeCompare(formatLineName(b)))
          .map(([line, _]) => {
            const lineColor = getLineColor(line).primary;
            const formattedName = formatLineName(line);

            const points = [];
            let currentStatus = null;

            processedData.forEach((entry, index) => {
              const status = entry.statuses[line]?.status;
              const severity = STATUS_MAPPING[status]?.severity || 0;
              const timestamp = new Date(entry.timestamp).getTime();

              // Skip good service
              if (severity <= STATUS_SEVERITY.GOOD) {
                if (currentStatus !== null) {
                  points.push({
                    x: timestamp,
                    y: null
                  });
                  currentStatus = null;
                }
                return;
              }

              // Break line if status changes
              if (status !== currentStatus && currentStatus !== null) {
                points.push({
                  x: timestamp,
                  y: null
                });
              }

              // Get fixed position for this line at this severity
              const position = severityPositions[severity].positions.get(line);
              const offset = position * 0.08; // Smaller offset for tighter stacking within severity levels

              points.push({
                x: timestamp,
                y: severity + offset, // Apply offset for stacking
                status,
                line: formattedName
              });

              currentStatus = status;
            });

            return {
              label: formattedName,
              data: points,
              borderColor: `rgba(${hexToRgb(lineColor)}, 0.8)`,        // Slight transparency
              backgroundColor: `rgba(${hexToRgb(lineColor)}, 0.3)`,   // Even lighter transparency
              fill: false,
              pointRadius: 0, // Initially set to 0, can adjust dynamically if needed
              borderWidth: 3,
              spanGaps: false,
              tension: 0
            };
          });

        setChartData({ datasets });
      } catch (error) {
        console.error('Failed to load disruption data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();

    return () => {
      // Cleanup: unregister the backgroundBands plugin
      Chart.unregister(backgroundBandsPlugin);
    };
  }, [timeRange]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeRange === '24h' ? 'hour' : timeRange === '7d' ? 'day' : 'week',
          displayFormats: {
            hour: 'HH:mm',
            day: 'MMM d',
            week: 'MMM d'
          },
          tooltipFormat: timeRange === '24h' ? 'HH:mm' : 'MMM d, HH:mm'
        },
        grid: {
          display: true,
          color: 'rgba(0,0,0,0.06)',
          drawBorder: false,
          tickLength: 10
        },
        ticks: {
          maxRotation: 0,
          padding: 10,
          color: '#6B7280',
          font: {
            size: 11,
            weight: '500'
          },
          maxTicksLimit: window.innerWidth < 768 ? 6 : 12
        }
      },
      y: {
        type: 'linear',
        min: 1, // Set to the baseline for "Minor"
        max: 5, // Maximum for "Suspended"
        ticks: {
          stepSize: 1,
          padding: 10,
          callback: (value) => {
            if (Number.isInteger(value)) {
              if (value === 1) return 'Minor';
              if (value === 2) return 'Moderate';
              if (value === 3) return 'Severe';
              if (value === 4) return 'Suspended';
            }
            return ''; // Hide non-integer values
          },
          color: '#374151',
          font: {
            size: window.innerWidth < 768 ? 11 : 13,
            weight: '600'
          }
        },
        grid: {
          drawTicks: false,
          color: (context) => {
            const value = Math.round(context.tick.value);
            return Number.isInteger(value) ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0)';
          },
          lineWidth: (context) => {
            const value = Math.round(context.tick.value);
            return Number.isInteger(value) ? 1.5 : 0;
          },
          drawBorder: false,
          offset: false
        }
      }      
    },
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(17, 24, 39, 0.95)',
        padding: { x: 8, y: 6 }, // Reduced padding for a more compact tooltip
        titleColor: '#fff',
        bodyColor: '#fff',
        bodyFont: {
          size: 12,
          weight: '500'
        },
        titleFont: {
          size: 12,
          weight: '600'
        },
        cornerRadius: 6,
        displayColors: true,
        callbacks: {
          title: (context) => {
            const date = new Date(context[0].parsed.x);
            return date.toLocaleString('en-GB', {
              dateStyle: timeRange === '24h' ? undefined : 'medium',
              timeStyle: 'short'
            });
          },
          label: (context) => {
            const line = context.dataset.label;
            const status = context.raw.status;
            return `${line}: ${status}`; // Added colon for clarity
          },
          labelColor: (context) => ({
            backgroundColor: context.dataset.borderColor,
            borderColor: context.dataset.borderColor
          })
        }
      },
      legend: {
        display: false
      }
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-sm p-3 sm:p-6 mb-6">
        <div className="animate-pulse">
          <div className="h-64 sm:h-96 bg-gray-50 rounded-lg"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-3 sm:p-6 mb-6">
      {/* Title and Time Range Selector */}
      <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:items-start sm:justify-between mb-3">
        <div>
          <h2 className="text-lg sm:text-xl font-bold text-gray-900">Network Disruption Timeline</h2>
          <p className="text-xs sm:text-sm text-gray-500 mt-1 flex items-center">
            <Info size={14} className="mr-1" />
            Showing service issues across all lines
          </p>
        </div>
        <TimeRangeSelector 
          selectedRange={timeRange} 
          onChange={setTimeRange}
        />
      </div>
      
      {/* Chart Container */}
      <div className="w-full flex justify-center">
        <div
          className="pb-5"
          style={{ 
            width: '95%', 
            height: window.innerWidth < 768 ? '300px' : '450px' 
          }}
        >
          {chartData && <Line ref={chartRef} data={chartData} options={chartOptions} />}
        </div>
      </div>
      
      {/* Legend - Hidden on Mobile */}
      <div className={`mt-3 ${window.innerWidth < 768 ? 'hidden' : 'flex'} items-center justify-start text-xs`}>
        <span className="inline-flex items-center px-2 py-1 bg-gray-50 text-gray-600 rounded-md mr-3">
          Blank areas = Good service
        </span>
        <span className="text-gray-500">Hover for details</span>
      </div>
    </div>
  );
};

export default NetworkDisruptionTimeline;
