//tfl-status-frontend/src/components/dashboard/LineDetailModal.js
import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import StatusBadge from './StatusBadge';
import { fetchLineHistory } from '../../utils/api';
import { getLineColor } from '../../utils/lineColors';
import { formatLineName } from '../../utils/lineNameUtils';
import { processChartData } from '../../utils/dataUtils';
import { STATUS_MAPPING } from '../../utils/statusUtils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const LineDetailModal = ({ line, status, onClose }) => {
  const [historicalData, setHistoricalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('24h');
  const chartRef = useRef(null);
  const lineColors = getLineColor(line);
  const formattedLineName = formatLineName(line);

  useEffect(() => {
    const loadData = async () => {
        setIsLoading(true);
        try {
          const data = await fetchLineHistory(line, timeRange);
          // Use processChartData instead of fillMissingData directly
          const processedData = processChartData(data, timeRange)
            .map(entry => ({
              timestamp: new Date(entry.timestamp).getTime(),
              severity: STATUS_MAPPING[entry.statuses[line]?.status]?.severity || 0
            }))
            .sort((a, b) => a.timestamp - b.timestamp);
          
          setHistoricalData(processedData);
        } catch (error) {
          console.error('Failed to load line history:', error);
        } finally {
          setIsLoading(false);
        }
      };

    loadData();
  }, [line, timeRange]);

  const chartData = {
    datasets: [{
      label: 'Service Status',
      data: historicalData,
      borderColor: lineColors.primary,
      backgroundColor: `${lineColors.primary}33`,
      tension: 0.4,
      fill: true,
      parsing: {
        xAxisKey: 'timestamp',
        yAxisKey: 'severity'
      }
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        min: -1,
        max: 4,
        ticks: {
          stepSize: 1,
          callback: (value) => {
            const labels = {
              '-1': 'Information Only',
              '0': 'Good Service',
              '1': 'Minor Issues',
              '2': 'Moderate Issues',
              '3': 'Severe Issues',
              '4': 'Service Suspended'
            };
            return labels[value] || '';
          }
        }
      },
      x: {
        type: 'time',
        time: {
          unit: timeRange === '24h' ? 'hour' : timeRange === '7d' ? 'day' : 'week',
          displayFormats: {
            hour: 'HH:mm',
            day: 'dd MMM',
            week: 'dd MMM'
          },
          tooltipFormat: timeRange === '24h' ? 'HH:mm' : 'dd MMM HH:mm'
        },
        title: {
          display: true,
          text: 'Time'
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const labels = {
              '-1': 'Information Only',
              '0': 'Good Service',
              '1': 'Minor Issues',
              '2': 'Moderate Issues',
              '3': 'Severe Issues',
              '4': 'Service Suspended'
            };
            return `Status: ${labels[context.raw.severity] || 'Unknown'}`;
          }
        }
      },
      legend: {
        display: true,
        position: 'top'
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 sm:p-4 z-50">
      <div className="bg-white rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div 
          className="p-4 sm:p-6"
          style={{ borderTop: `4px solid ${lineColors.primary}` }}
        >
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 
                className="text-2xl font-bold"
                style={{ color: lineColors.primary }}
              >
                {formattedLineName}
              </h2>
              <div className="mt-2">
                <StatusBadge status={status.status} />
              </div>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 text-xl font-bold p-2"
            >
              ×
            </button>
          </div>

          <div className="space-y-4 sm:space-y-6">
            <div className="flex flex-wrap gap-2 sm:gap-4">
              {['24h', '7d', '30d'].map((range) => (
                <button
                  key={range}
                  onClick={() => setTimeRange(range)}
                  className={`px-3 py-1 sm:px-4 sm:py-2 rounded-lg text-sm sm:text-base transition-colors duration-200 ${
                    timeRange === range
                      ? 'text-white'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                  style={{
                    backgroundColor: timeRange === range ? lineColors.primary : undefined,
                  }}
                >
                  {range}
                </button>
              ))}
            </div>

            <div className="h-[300px] sm:h-[400px] bg-white rounded-lg p-4">
              {isLoading ? (
                <div className="flex items-center justify-center h-full">
                  <div 
                    className="animate-spin rounded-full h-8 w-8 border-b-2"
                    style={{ borderColor: lineColors.primary }}
                  />
                </div>
              ) : historicalData.length > 0 ? (
                <Line 
                  ref={chartRef}
                  data={chartData}
                  options={chartOptions}
                  className="max-w-full"
                />
              ) : (
                <div className="flex items-center justify-center h-full text-gray-500">
                  No historical data available
                </div>
              )}
            </div>

            <div className="text-sm text-gray-500 mt-4">
              <p>
                This chart shows the service status history for the {line} line.
                The higher the point on the chart, the more severe the service disruption.
              </p>
              <ul className="mt-2 space-y-1">
                <li>• Level 0: Good Service</li>
                <li>• Level 1: Minor Issues</li>
                <li>• Level 2: Moderate Issues</li>
                <li>• Level 3: Severe Issues</li>
                <li>• Level 4: Service Suspended</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineDetailModal;