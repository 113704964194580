// src/utils/statusUtils.js

export const STATUS_SEVERITY = {
  NONE: -1,        // Informational only, no service impact
  GOOD: 0,         // Normal service
  MINOR: 1,        // Minor disruption
  MODERATE: 2,     // Moderate disruption
  SEVERE: 3,       // Severe disruption
  SUSPENDED: 4     // No service
};

export const STATUS_MAPPING = {
  // Normal Service
  'Good service': { 
    severity: STATUS_SEVERITY.GOOD, 
    score: 100, 
    category: 'Normal' 
  },
  
  // Minor Issues
  'Minor delays': { 
    severity: STATUS_SEVERITY.MINOR, 
    score: 75, 
    category: 'Minor Delays' 
  },
  'Part closure': { 
    severity: STATUS_SEVERITY.MINOR, 
    score: 75, 
    category: 'Minor Disruption' 
  },
  'Reduced service': { 
    severity: STATUS_SEVERITY.MINOR, 
    score: 75, 
    category: 'Minor Disruption' 
  },
  'Change of frequency': { 
    severity: STATUS_SEVERITY.MINOR, 
    score: 75, 
    category: 'Minor Disruption' 
  },
  'Diverted': { 
    severity: STATUS_SEVERITY.MINOR, 
    score: 75, 
    category: 'Minor Disruption' 
  },
  'Issues reported': { 
    severity: STATUS_SEVERITY.MINOR, 
    score: 75, 
    category: 'Minor Issues' 
  },
  'Bus service': { 
    severity: STATUS_SEVERITY.MINOR, 
    score: 75, 
    category: 'Alternative Service' 
  },

  // Moderate Issues
  'Part suspended': { 
    severity: STATUS_SEVERITY.MODERATE, 
    score: 50, 
    category: 'Moderate Disruption' 
  },
  'Part closed': { 
    severity: STATUS_SEVERITY.MODERATE, 
    score: 50, 
    category: 'Moderate Disruption' 
  },
  'Planned closure': { 
    severity: STATUS_SEVERITY.MODERATE, 
    score: 50, 
    category: 'Planned Works' 
  },
  'Special service': { 
    severity: STATUS_SEVERITY.MODERATE, 
    score: 50, 
    category: 'Modified Service' 
  },

  // Severe Issues
  'Severe delays': { 
    severity: STATUS_SEVERITY.SEVERE, 
    score: 25, 
    category: 'Severe Delays' 
  },
  'Not running': { 
    severity: STATUS_SEVERITY.SEVERE, 
    score: 0, 
    category: 'Service Down' 
  },

  // Service Suspended
  'Suspended': { 
    severity: STATUS_SEVERITY.SUSPENDED, 
    score: 0, 
    category: 'Suspended' 
  },
  'Service Closed': { 
    severity: STATUS_SEVERITY.NONE, 
    score: null,
    category: 'Information'
  },
  'No service': { 
    severity: STATUS_SEVERITY.SUSPENDED, 
    score: 0, 
    category: 'No Service' 
  },

  // Informational (No Impact)
  'Information': { 
    severity: STATUS_SEVERITY.NONE, 
    score: null, 
    category: 'Information' 
  },
  'Exit only': { 
    severity: STATUS_SEVERITY.NONE, 
    score: null, 
    category: 'Station Access' 
  },
  'No Step Free Access': { 
    severity: STATUS_SEVERITY.NONE, 
    score: null, 
    category: 'Accessibility' 
  },
  'No issues': { 
    severity: STATUS_SEVERITY.GOOD, 
    score: 100, 
    category: 'Normal' 
  }
};

export const getStatusColor = (status) => {
  const severity = STATUS_MAPPING[status]?.severity ?? STATUS_SEVERITY.NONE;
  
  switch (severity) {
    case STATUS_SEVERITY.NONE:
      return 'bg-gray-400';
    case STATUS_SEVERITY.GOOD:
      return 'bg-green-500';
    case STATUS_SEVERITY.MINOR:
      return 'bg-yellow-500';
    case STATUS_SEVERITY.MODERATE:
      return 'bg-orange-500';
    case STATUS_SEVERITY.SEVERE:
      return 'bg-red-500';
    case STATUS_SEVERITY.SUSPENDED:
      return 'bg-purple-500';
    default:
      return 'bg-gray-500';
  }
};

export const getStatusCategory = (status) => {
  return STATUS_MAPPING[status]?.category || 'Unknown';
};

export const getStatusScore = (status) => {
  const score = STATUS_MAPPING[status]?.score;
  return score !== null ? score : null; // Return null for informational statuses
};

export const isServiceAffectingStatus = (status) => {
  return STATUS_MAPPING[status]?.severity >= STATUS_SEVERITY.MINOR;
};

// Helper function to get status description for charts
export const getStatusLabel = (severity) => {
  switch (severity) {
    case STATUS_SEVERITY.NONE:
      return 'Information Only';
    case STATUS_SEVERITY.GOOD:
      return 'Good Service';
    case STATUS_SEVERITY.MINOR:
      return 'Minor Issues';
    case STATUS_SEVERITY.MODERATE:
      return 'Moderate Issues';
    case STATUS_SEVERITY.SEVERE:
      return 'Severe Issues';
    case STATUS_SEVERITY.SUSPENDED:
      return 'Service Suspended';
    default:
      return 'Unknown';
  }
};