// src/utils/api.js
import historicalData from '../data/historicalData.json';

export const fetchLineHistory = async (line, timeRange) => {
    const endTime = new Date();
    const startTime = new Date();
  
    switch (timeRange) {
      case '24h':
        startTime.setHours(startTime.getHours() - 24);
        break;
      case '7d':
        startTime.setDate(startTime.getDate() - 7);
        break;
      case '30d':
        startTime.setDate(startTime.getDate() - 30);
        break;
      default:
        startTime.setHours(startTime.getHours() - 24);
    }
  
    console.log(`Fetching data from ${startTime.toISOString()} to ${endTime.toISOString()}`);
  
    const data = historicalData.filter(entry => {
      const timestamp = new Date(entry.timestamp);
      return timestamp >= startTime && timestamp <= endTime;
    });
  
    console.log(`Number of data points after filtering: ${data.length}`);
  
    if (!line) {
      return data;
    }
  
    return data
      .map(entry => ({
        timestamp: entry.timestamp,
        statuses: { 
          [line]: entry.statuses[line] || { status: 'Unknown', timestamp: entry.timestamp }
        }
      }))
      .filter(entry => entry.statuses[line]?.status !== 'Unknown')
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  };