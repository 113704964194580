export const LINE_COLORS = {
    // Base lines
    'bakerloo': {
        primary: '#b26300',
        hover: '#995500',
        text: '#ffffff'
    },
    'central': {
        primary: '#dc241f',
        hover: '#c6201c',
        text: '#ffffff'
    },
    'circle': {
        primary: '#ffc80a',
        hover: '#f0ba00',
        text: '#000000'
    },
    'district': {
        primary: '#007d32',
        hover: '#006428',
        text: '#ffffff'
    },
    'dlr': {
        primary: '#00a4a7',
        hover: '#009997',
        text: '#ffffff'
    },
    'elizabeth': {
        primary: '#60399e',
        hover: '#55328b',
        text: '#ffffff'
    },
    'elizabeth-line': {
        primary: '#60399e',
        hover: '#55328b',
        text: '#ffffff'
    },
    'hammersmith': {
        primary: '#f589a6',
        hover: '#f37194',
        text: '#000000'
    },
    'hammersmith-city': {
        primary: '#f589a6',
        hover: '#f37194',
        text: '#000000'
    },
    'jubilee': {
        primary: '#838d93',
        hover: '#768087',
        text: '#ffffff'
    },
    'metropolitan': {
        primary: '#9b0058',
        hover: '#82004a',
        text: '#ffffff'
    },
    'northern': {
        primary: '#000000',
        hover: '#000000',
        text: '#ffffff'
    },
    'piccadilly': {
        primary: '#0019a8',
        hover: '#00158f',
        text: '#ffffff'
    },
    'victoria': {
        primary: '#039be5',
        hover: '#038acc',
        text: '#ffffff'
    },
    'waterloo': {
        primary: '#76d0bd',
        hover: '#63c9b4',
        text: '#000000'
    },
    'waterloo-city': {
        primary: '#76d0bd',
        hover: '#63c9b4',
        text: '#000000'
    },
    'london-overground': {
        primary: '#fa7b05',
        hover: '#e16f05',
        text: '#ffffff'
    },
    'overground': {
        primary: '#fa7b05',
        hover: '#e16f05',
        text: '#ffffff'
    },

    // New lines with official colors
    'liberty': {
        primary: '#61686b',
        hover: '#515657',
        text: '#ffffff'
    },
    'lioness': {
        primary: '#ffa600',
        hover: '#e69500',
        text: '#000000'
    },
    'mildmay': {
        primary: '#006fe6',
        hover: '#005fc6',
        text: '#ffffff'
    },
    'suffragette': {
        primary: '#18a95d',
        hover: '#148a4c',
        text: '#ffffff'
    },
    'weaver': {
        primary: '#9b0058',
        hover: '#82004a',
        text: '#ffffff'
    },
    'windrush': {
        primary: '#dc241f',
        hover: '#c6201c',
        text: '#ffffff'
    },
    'cable-car': {
        primary: '#dc241f',
        hover: '#c6201c',
        text: '#ffffff'
    }
};

export const getLineColor = (line) => {
    // Handle different text variations
    const normalizedLine = line.toLowerCase()
        .replace(/ & /g, '-')
        .replace(/ /g, '-')
        .replace('&', '-');

    // Check for exact match first
    if (LINE_COLORS[normalizedLine]) {
        return LINE_COLORS[normalizedLine];
    }

    // Check for partial matches
    const partialMatch = Object.keys(LINE_COLORS).find(key => 
        normalizedLine.includes(key) || key.includes(normalizedLine)
    );

    return LINE_COLORS[partialMatch] || {
        primary: '#666666',
        hover: '#555555',
        text: '#ffffff'
    };
};