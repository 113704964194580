// src/components/dashboard/PerformanceLeaderboard.js

import React, { useState, useEffect } from 'react';
import { getLineColor } from '../../utils/lineColors';
import { fetchLineHistory } from '../../utils/api';
import { Medal, Clock } from 'lucide-react';
import { processChartData, calculatePerformanceMetrics } from '../../utils/dataUtils';

// TimeRangeSelector Component
const TimeRangeSelector = ({ selectedRange, onChange }) => {
  const ranges = [
    { id: '24h', label: 'Last 24h' },
    { id: '7d', label: 'This Week' },
    { id: '30d', label: 'This Month' }
  ];

  return (
    <div className="flex flex-wrap gap-2 mb-4 sm:mb-6">
      {ranges.map((range) => (
        <button
          key={range.id}
          onClick={() => onChange(range.id)}
          className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg text-sm transition-all duration-200 ${
            selectedRange === range.id
              ? 'bg-blue-600 text-white shadow-lg scale-105'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:scale-105'
          }`}
        >
          {range.label}
        </button>
      ))}
    </div>
  );
};

// ServiceBadge Component
const ServiceBadge = ({ percentage }) => {
  let color;
  if (percentage >= 90) color = 'bg-green-100 text-green-700 border-green-300';
  else if (percentage >= 75) color = 'bg-blue-100 text-blue-700 border-blue-300';
  else if (percentage >= 50) color = 'bg-yellow-100 text-yellow-700 border-yellow-300';
  else color = 'bg-red-100 text-red-700 border-red-300';

  return (
    <div className={`px-2 sm:px-3 py-1 rounded-full border ${color} text-xs sm:text-sm font-semibold whitespace-nowrap`}>
      {percentage}% Good Service
    </div>
  );
};

// StreakBadge Component
const StreakBadge = ({ hours, type }) => {
  return (
    <div className="flex items-center text-xs text-gray-500 whitespace-normal break-words">
      <Clock size={12} className="mr-1 flex-shrink-0" />
      {hours > 0 ? (
        <span className="block">
          Best {type} streak: {hours}h
        </span>
      ) : (
        <span className="block">No {type} streak yet</span>
      )}
    </div>
  );
};

// LinePerformanceRow Component
const LinePerformanceRow = ({ lineData, rank, type }) => {
  const lineColors = getLineColor(lineData.originalLine);
  const isTop = type === 'top';
  
  return (
    <div 
      className="p-3 sm:p-4 rounded-xl transition-all duration-300 hover:shadow-lg min-h-[5rem] sm:h-24"
      style={{
        background: `linear-gradient(to right, ${lineColors.primary}0A, ${lineColors.primary}15)`,
        borderLeft: `4px solid ${lineColors.primary}`
      }}
    >
      <div className="flex justify-between h-full">
        <div className="flex items-start space-x-2 sm:space-x-4 min-w-0">
          {isTop && (
            <div className="relative flex-shrink-0">
              <Medal 
                size={20} 
                className={rank === 0 ? 'text-yellow-500' : rank === 1 ? 'text-gray-400' : 'text-orange-600'}
              />
              {rank === 0 && <span className="absolute -top-2 -right-2 text-xs">✨</span>}
            </div>
          )}
          <div className="min-w-0 flex-1">
            <div className="font-semibold text-sm sm:text-base truncate" style={{ color: lineColors.primary }}>
              {lineData.line}
            </div>
            <div className="mt-1 sm:mt-2 space-y-0.5 sm:space-y-1">
            <StreakBadge hours={Math.round(lineData.longestGoodStreak / 60)} type="good" />
                {!isTop && (
                <StreakBadge hours={Math.round(lineData.longestBadStreak / 60)} type="disruption" />
                )}
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 ml-2">
          <ServiceBadge percentage={Math.round(lineData.goodServicePercentage)} />
        </div>
      </div>
    </div>
  );
};

const PerformanceLeaderboard = () => {
  const [timeRange, setTimeRange] = useState('24h');
  const [performanceData, setPerformanceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
        setIsLoading(true);
        try {
            const data = await fetchLineHistory(null, timeRange);
            const processedData = processChartData(data, timeRange);
            const metrics = calculatePerformanceMetrics(processedData);
            setPerformanceData(metrics);
        } catch (error) {
            console.error('Failed to fetch historical data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    loadData();
  }, [timeRange]);

  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 mt-4 sm:mt-8">
        <div className="animate-pulse">
          <div className="h-6 sm:h-8 bg-gray-200 rounded w-1/3 mb-4 sm:mb-6"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="h-16 bg-gray-100 rounded-xl"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const topPerformers = performanceData?.slice(0, 3) || [];
  const worstPerformers = performanceData?.slice(-3).reverse() || [];

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 mt-4 sm:mt-8">
      <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-gray-800">Service Reliability Ranking</h2>
      <TimeRangeSelector selectedRange={timeRange} onChange={setTimeRange} />
      
      {performanceData?.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
          <div>
            <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4 text-green-600 flex items-center">
              <span className="mr-2">🏆</span> Top Performers
            </h3>
            <div className="space-y-2 sm:space-y-3">
              {topPerformers.map((lineData, index) => (
                <LinePerformanceRow 
                  key={lineData.line}
                  lineData={lineData}
                  rank={index}
                  type="top"
                />
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-base sm:text-lg font-semibold mb-3 sm:mb-4 text-red-600 flex items-center">
              <span className="mr-2">⚠️</span> Needs Improvement
            </h3>
            <div className="space-y-2 sm:space-y-3">
              {worstPerformers.map((lineData, index) => (
                <LinePerformanceRow 
                  key={lineData.line}
                  lineData={lineData}
                  rank={index}
                  type="bottom"
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center py-6 sm:py-8 text-gray-500">
          No performance data available for this time period yet
        </div>
      )}

      <div className="mt-4 sm:mt-6 pt-4 border-t border-gray-100">
        <div className="text-xs sm:text-sm text-gray-500">
          <p className="mb-2">
            Rankings are based on the percentage of time each line operated with "Good service" status.
            Service closure periods are excluded from calculations.
          </p>
          <p>
            Data shown for: {timeRange === '24h' ? 'Last 24 hours' : timeRange === '7d' ? 'Past week' : 'Past month'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PerformanceLeaderboard;
