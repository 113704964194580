// src/components/layout/Header.js
import React from 'react';
import { format } from 'date-fns';

const Header = ({ lastUpdated }) => {
    return (
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 sm:py-6">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
            <div className="mb-4 sm:mb-0">
              <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                London Transport Status
              </h1>
              <p className="mt-1 sm:mt-2 text-sm sm:text-base text-gray-600">
                Real-time service updates
              </p>
            </div>
            {lastUpdated && (
              <div className="text-left sm:text-right">
                <p className="text-xs sm:text-sm text-gray-500">Last Status Change:</p>
                <p className="text-xs sm:text-sm font-medium text-gray-900">
                  {format(new Date(lastUpdated), 'dd MMM yyyy HH:mm:ss')}
                </p>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  };

export default Header;